import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";
import Swal from "sweetalert2";

// Connects to data-controller="consent-form-signer"
export default class extends Controller {
  static targets = [
    "field",
    "formFiller",
    "textFieldInput",
    "selectInput",
    "signaturePadCanvas",
    "form",
    "button",
  ];

  connect() {
    this.currentFieldIndex = 0;

    this.initializeSignaturePad();
    this.showField();
  }

  initializeSignaturePad() {
    this.signaturePad = new SignaturePad(this.signaturePadCanvasTarget, {
      backgroundColor: "rgba(0, 0, 0, 0)",
    });

    this.resizeSignaturePad();
  }

  resizeSignaturePad() {
    if (this.signaturePad) {
      this.signaturePad.clear();
      let ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.signaturePadCanvasTarget.width =
        this.signaturePadCanvasTarget.offsetWidth * ratio;
      this.signaturePadCanvasTarget.height =
        this.signaturePadCanvasTarget.offsetHeight * ratio;
      this.signaturePadCanvasTarget.getContext("2d").scale(ratio, ratio);
    }
  }

  nextField(event) {
    event.preventDefault();

    // check required
    const field = this.fieldTargets[this.currentFieldIndex];
    const required = field.dataset.fieldRequired === "true";
    const currentInput = this.getCurrentInput();

    if (required) {
      let valid = true;

      if (field.dataset.fieldType == "push_button" && currentInput.isEmpty()) {
        valid = false;
      }

      if (field.dataset.fieldType !== "push_button" && !currentInput.value) {
        valid = false;
      }

      if (!valid) {
        Swal.fire({
          title: "Error",
          text: `A value for ${field.dataset.fieldName} is required`,
          icon: "error",
        });
        return;
      }
    }

    this.setValue(field);

    if (this.currentFieldIndex + 1 < this.fieldTargets.length) {
      this.currentFieldIndex++;
      this.currentFieldIndexChanged();
      this.showField();
    } else {
      this.formTarget.submit();
    }
  }

  selectField(event) {
    event.preventDefault();
    this.setValue(this.fieldTargets[this.currentFieldIndex]);

    const field = this.fieldTargets.find((x) => x == event.currentTarget);
    if (field) {
      this.currentFieldIndex = this.fieldTargets.indexOf(field);
      this.currentFieldIndexChanged();
      this.showField();
    }
  }

  showField() {
    const field = this.fieldTargets[this.currentFieldIndex];
    const title = this.formFillerTarget.querySelector("h2");

    // Set title
    title.innerText = field.dataset.fieldName;

    // Show the proper input field
    this.showCorrectInputField(field);

    // Mark selected
    this.fieldTargets.forEach((target) => {
      target.classList.remove("selected");
    });

    field.classList.add("selected");

    // Set type and value of input
    const currentInput = this.getCurrentInput();
    if (currentInput == this.textFieldInputTarget) {
      // Set Type
      currentInput.setAttribute("type", field.dataset.fieldType);

      // Set Value
      const value = field.querySelector("input[type=hidden]").value;

      if (value) {
        currentInput.value = value;
      } else {
        if (field.dataset.fieldType == "date") {
          currentInput.valueAsDate = new Date();
        } else if (field.dataset.fieldType == "time") {
          const now = new Date();
          const hours = String(now.getHours()).padStart(2, "0");
          const minutes = String(now.getMinutes()).padStart(2, "0");

          currentInput.value = `${hours}:${minutes}`;
        }
      }
    }

    // Scroll element into view
    field.scrollIntoView(true);
  }

  showCorrectInputField(field) {
    // Clear out fields
    this.textFieldInputTarget.value = "";
    this.selectInputTarget.value = "";
    this.signaturePad.clear();

    let showText = false;
    let showSelect = false;
    let showSignaturePad = false;

    switch (field.dataset.fieldType) {
      case "signature":
      case "push_button":
        showText = false;
        showSelect = false;
        showSignaturePad = true;
        break;

      case "date":
      case "time":
      case "text":
        showText = true;
        showSelect = false;
        showSignaturePad = false;
        break;

      case "checkbox":
        showText = false;
        showSelect = true;
        showSignaturePad = false;
        break;
    }

    if (showText) {
      this.textFieldInputTarget.classList.remove("d-none");
      this.selectInputTarget.classList.add("d-none");
      this.signaturePadCanvasTarget.classList.add("d-none");
    }

    if (showSelect) {
      this.textFieldInputTarget.classList.add("d-none");
      this.selectInputTarget.classList.remove("d-none");
      this.signaturePadCanvasTarget.classList.add("d-none");
    }

    if (showSignaturePad) {
      this.textFieldInputTarget.classList.add("d-none");
      this.selectInputTarget.classList.add("d-none");
      this.signaturePadCanvasTarget.classList.remove("d-none");
    }
  }

  currentFieldIndexChanged() {
    if (this.currentFieldIndex + 1 == this.fieldTargets.length) {
      this.buttonTarget.innerText = "Complete";
    } else {
      this.buttonTarget.innerText = "Next";
    }
  }

  getCurrentInput() {
    const field = this.fieldTargets[this.currentFieldIndex];
    let ret = null;

    switch (field.dataset.fieldType) {
      case "push_button":
      case "signature":
        ret = this.signaturePad;
        break;

      case "date":
      case "time":
      case "text":
        ret = this.textFieldInputTarget;
        break;

      case "checkbox":
        ret = this.selectInputTarget;
        break;
    }

    return ret;
  }

  setValue(field) {
    const currentInput = this.getCurrentInput();

    // Set the value appropriately
    const hiddenInput = field.querySelector("input");
    const userFacingValue = field.querySelector("span");

    if (
      field.dataset.fieldType == "push_button" ||
      field.dataset.fieldType == "signature"
    ) {
      hiddenInput.value = this.signaturePad.toDataURL("image/png");

      if (userFacingValue.querySelector("img")) {
        userFacingValue.querySelector("img").remove();
      }

      const imageElement = document.createElement("img");
      imageElement.src = this.signaturePad.toDataURL("image/png");

      userFacingValue.appendChild(imageElement);
    } else if (field.dataset.fieldType == "checkbox") {
      if (currentInput.value == "1") {
        userFacingValue.innerHTML = "&check;";
      }

      hiddenInput.value = currentInput.value;
    } else if (field.dataset.fieldType == "date") {
      const [year, month, day] = currentInput.value.split("-");

      userFacingValue.innerText = `${month}/${day}/${year}`;
      hiddenInput.value = currentInput.value;
    } else if (field.dataset.fieldType == "time") {
      const [hours, minutes] = currentInput.value.split(":");
      let hour = parseInt(hours, 10);

      const period = hour >= 12 ? "PM" : "AM";

      if (hour === 0) {
        hour = 12;
      } else if (hour > 12) {
        hour = hour - 12;
      }

      const formattedHour = String(hour).padStart(2, "0");
      userFacingValue.innerText = `${formattedHour}:${minutes} ${period}`;
      hiddenInput.value = currentInput.value;
    } else {
      userFacingValue.innerText = currentInput.value;
      hiddenInput.value = currentInput.value;
    }
  }
}
