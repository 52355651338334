import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="intake-document-templates--form"
export default class extends Controller {
  static targets = ["inSystemFields", "pdfFields"];

  connect() {}

  toggleFields(event) {
    if (event.target.value == "in_system") {
      this.inSystemFieldsTarget.classList.remove("d-none");
      this.pdfFieldsTarget.classList.add("d-none");
    } else if (event.target.value == "pdf") {
      this.inSystemFieldsTarget.classList.add("d-none");
      this.pdfFieldsTarget.classList.remove("d-none");
    }
  }
}
